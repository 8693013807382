import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

    if (isInStandaloneMode()) {
      const anchors = document.getElementsByClassName('external')
      for (var i=0; i<anchors.length; i++){
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}
