import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "panel", "main", "cover", "item" ]

  open(event) {
    event.preventDefault()
    this.panelTarget.style.left = "0px";
    this.coverTarget.style.display = "block";
  }

  close(event) {
    event.preventDefault()
    this.panelTarget.style.left = "-260px";
    this.coverTarget.style.display = "none";
  }
}
