import LocalTime from "local-time"
LocalTime.config.i18n["sv"] = {
  date: {
    dayNames: [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag"
    ],
    abbrDayNames: [
      "Sön",
      "Mån",
      "Tis",
      "Ons",
      "Tor",
      "Fre",
      "Lör"
    ],
    monthNames: [
      "Januari",
      "Februari",
      "Mars",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Augusti",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    abbrMonthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec"
    ],
    yesterday: "Igår",
    today: "Idag",
    tomorrow: "Imorgon",
    on: "{date}",
    formats: {
      default: "%e %b , %Y",
      thisYear: "%e %b"
    }
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "En {time}",
    singularAn: "En {time}",
    elapsed: "{time} sedan",
    second: "sekund",
    seconds: "sekunder",
    minute: "minut",
    minutes: "minuter",
    hour: "timme",
    hours: "timmar",
    formats: {
      default: "%H:%M"
    }
  },
  datetime: {
    at: "{date} kl. {time}",
    formats: {
      default: "%e %b, %Y kl. %H:%M"
    }
  }
}

LocalTime.config.locale = "sv"
LocalTime.start()
